@tailwind base;
/* @tailwind components; */
@tailwind utilities;

@layer base {
	.xy {
		@apply flex justify-center items-center;
	}

	.fx {
		@apply flex items-center;
	}

	.fy {
		@apply flex flex-col items-center;
	}

	.b {
		border: 1px solid red;
	}

	.o {
		outline: 1px solid red;
	}

	.brightness-button {
		@apply transition-[filter] duration-150 hover:brightness-95 dark:hover:brightness-125;
	}

	h1 {
		@apply text-3xl;
	}
	h2 {
		@apply text-2xl;
	}
	h3 {
		@apply text-xl my-2;
	}
	h4 {
		@apply text-lg;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-semibold text-skin-primary;
	}
}

@layer utilities {
	.lazy-loading {
		@apply bg-skin-alt animate-pulse-fast;
	}
}

.vite-logo{
	color: #0070ff;
}